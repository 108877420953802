import React from "react";

export default function Home() {
    return(
        <div style={{ color: `purple`}}>
            <h1>Header</h1>
            <p>paragraph text</p>
            <img src="https://source.unsplash.com/random/400x200" alt="" />
        </div>
    );
}